.heading {
    font-family: 'PP-Editorial', serif;
    font-size: 3.8em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.025em;
}

.black {
    color: var(--black);
}

.white {
    color: #fff;
}

.page_button {
    position: relative;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: -0.9px;
    font-size: 1.5em;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -2.5%;
        height: 100%;
        width: 105%;
        mix-blend-mode: difference;
        transition: 0.8s ease;
        transform: scaleY(0);
        transform-origin: bottom;
        border-radius: 5px;
    }

    &:hover {
        &:before {
            transform: scaleY(1);
        }
    }


}

.invert_button {
    color: white;

    &:before {
        background-color: var(--yellow);
    }
}

@media screen and (min-width: 1750px) {
    .heading {
        font-size: 4.8em;
    }
    .page_button {
        font-size: 2em;
    }
}

@media screen and (max-width: 1300px) {
    .heading {
        font-size: 2.8em;
    }
}

@media screen and (max-width: 1024px) {
    .heading {
        font-size: 2.7em;
    }
    .page_button {
        font-size: 1.3em;
    }
}

