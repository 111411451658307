.mainTitle {
    color: var(--black);
    font-family: 'PP-Editorial', serif;
    font-size: 6em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.025em;

} 

.nameTitle {
    font-size: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 2em;
}

.subTitle > span:nth-child(-n + 6) {
    font-size: 'Inter', sans-serif;
    font-weight: 400;
}

.subTitle > span:nth-child(n + 6) {
    font-family: 'PP-Editorial-Italic', serif;
    font-weight: 400;
}

@media screen and (min-width: 1750px) {
    .mainTitle {
        font-size: 7.5em;
    }  
    .nameTitle {
        font-size: 2.75em;
    } 
    .subTitle {
        font-size: 2.25em;
    }
}

@media screen and (max-width: 1300px) {
    .mainTitle {
        font-size: 5em;
    }  
    .nameTitle {
        font-size: 1.5em;
    } 
}

@media screen and (max-width: 1024px) {
    .mainTitle {
        font-size: 5em;
    }  
    .nameTitle {
        font-size: 1.5em;
    } 
    .subTitle {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 375px) {
    .mainTitle {
        font-size: 4em;
    }  
}