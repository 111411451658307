.heading_container {
    margin: 30px 0px 10px 0px;
}

.heading {
    font-family: 'PP-Editorial', serif;
    letter-spacing: 1px;
    font-size: 2em;
}

@media screen and (min-width: 1750px) {
    .heading_container {
        margin: 40px 0px 22px 0px;
    }
    .heading {
        font-size: 2.75em;
    }
}

@media screen and (max-width: 1300px) {
    .heading {
        font-size: 1.9em;
    }
}

@media screen and (max-width: 1024px) {
    .heading {
        font-size: 1.4em;
    }
}