:root {
  --yellow: #FBE095;
  --red: #FC7E7E;
  --black: #100D0E;
  --text: #313131;

  --paddingSide: 4em;
}

@font-face {
  font-family: 'PP-Editorial';
  src: url('./resources/types/ppEditorial/regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PP-Editorial-Italic';
  src: url('./resources/types/ppEditorial/italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PP-Editorial-Bold';
  src: url('./resources/types/ppEditorial/ultrabold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PP-Editorial-Bold-Italic';
  src: url('./resources/types/ppEditorial/ultraboldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PP-Editorial-Light';
  src: url('./resources/types/ppEditorial/ultralight.otf') format('opentype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'PP-Editorial';
  src: url('./resources/types/ppEditorial/ultralightItalic.otf') format('opentype');
  font-weight: light;
  font-style: italic;
}

html, body {
  font-family: 'Inter', sans-serif;
}

@media screen and (min-width: 1750px) {
  :root {
    --paddingSide: 5.5em;
  }
}


@media screen and (max-width: 1300px) {
  :root {
    --paddingSide: 3.75em;
  }
}

@media screen and (max-width: 1075px) {
  :root {
    --paddingSide: 2em;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --paddingSide: 1.5em;
  }
}
